import React, { useEffect, useRef } from "react";

const AppleMapKit = ({ config, markerClick, ...props }) => {
    const mapRef = useRef(null);
    const mapInstance = useRef(null); // Reference to hold the MapKit instance

    useEffect(() => {
        if (mapInstance.current) {
            const { center } = config
            const newCenter = new window.mapkit.Coordinate(center.lat, center.lng);
            const newSpan = new window.mapkit.CoordinateSpan(0.2, 0.2); // You can adjust the zoom level here
            const newRegion = new window.mapkit.CoordinateRegion(newCenter, newSpan);
            mapInstance.current.region = newRegion; // Recenter the map
        }
    }, [config]);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js";
        script.async = true;
        script.onload = () => {
            window.mapkit.init({
                authorizationCallback: function (done) {
                    done(process.env.REACT_APP_MAPKIT_KEY);
                },
            });

            const { center } = config

            const initialLocation = new window.mapkit.Coordinate(center.lat, center.lng);
            const map = new window.mapkit.Map(mapRef.current);

            const initialSpan = new window.mapkit.CoordinateSpan(0.2, 0.2);
            const region = new window.mapkit.CoordinateRegion(initialLocation, initialSpan);

            config.markers.forEach(e => {
                const markerLocation = new window.mapkit.Coordinate(e.latitude, e.longitude);
                const marker = new window.mapkit.MarkerAnnotation(markerLocation, {
                    title: e.city,
                    // subtitle: "A great city!",
                    color: 'red',
                });

                marker.addEventListener("select", (event) => {
                    console.log("Marker clicked:", event);
                    markerClick(e)
                });

                map.addAnnotation(marker);
            });

            mapInstance.current = map; // Save the map instance for later use

            map.region = region;
        };

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return <div ref={mapRef} {...props} />;
};

export default AppleMapKit;
